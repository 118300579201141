import React, { useEffect, useState } from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Footer from "../../Layout/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getEducationalVideos, getAptVideos, getProgram, getExerciseType } from "../../../actions/user_program";
import CustomVideoPlayer from '../AssessmentTimer/CustomVideoPlayer';
import Header from "../../Layout/Header";
import { cross_origin } from "../../../utils/constants";
import imgNotFound from "../../images/broken-hd.png"
import { useLocation } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { useNavigate } from "react-router-dom";


function EducationVideo({ EducationVideosSuccess, getEducationalVideos, educational_video, aptVideos, getProgram, program }) {
    const [currentEdPage, setCurrentEdPage] = useState(educational_video ? parseInt(educational_video.meta.current_page) : 1);
    const [itemsEdPerPage, setItemsEdPerPage] = useState(8);
    const [totalEdPages, setEdTotalPages] = useState(0)

    const [programEdVideos, setProgramEdVideos] = useState(null)

    const [categoryId, setCategoryId] = useState(null)
    let location = useLocation();



    useEffect(() => {
        getProgram()

    }, []);

    // Videos by category id
    useEffect(() => {
        const categoryId = location?.state?.category;
        if (categoryId) {
            getEducationalVideos(program?.id, currentEdPage, itemsEdPerPage, 1, "1", categoryId);

            setEdTotalPages(educational_video ? parseInt(educational_video.meta.last_page) : 1)
            setProgramEdVideos(educational_video ? educational_video.data : [])
        }
        if (educational_video?.data) {
            const uniqueItems = filterUniqueItems(educational_video.data);
        }
    }, [currentEdPage, itemsEdPerPage, categoryId]);


    useEffect(() => {

        setEdTotalPages(educational_video ? parseInt(educational_video.meta.last_page) : 1)
        setProgramEdVideos(educational_video ? educational_video.data : [])

    }, [educational_video]);

    const handleItemsEdPerPageChange = (e) => {
        setItemsEdPerPage(parseInt(e.target.value));
    };

    const filterUniqueItems = (arr) => {
        // Create a Set to store unique IDs
        const uniqueIds = new Set();

        // Filter the array based on the unique IDs
        const filteredArray = arr.filter((item) => {
            if (!uniqueIds.has(item.video)) {
                // If the 'id' is not in the Set, add it and include the item in the filtered array
                uniqueIds.add(item.video);
                return true;
            }
            return false;
        });

        return filteredArray;
    };

    return (
        <>
            <ScrollTopOnMount />
            <Header />
            <div className={"clearfix"}></div>
            <section className="videos">
                <div className="container">
                    { !EducationVideosSuccess ? (
                        <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 ">
                            <div className={"empty-page-loader"}>
                                <div className="spin-loader">
                                    <FadeLoader color={"#EC1246"} height={10} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {
                                programEdVideos && programEdVideos.length > 0 ?
                                    <div className="row mt-4 mb-5">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
                                            <div className="videos_headings">
                                                <h2 className="detail_span" style={{ color: "#fff" }}>EDUCATIONAL VIDEOS IN PROGRAM</h2>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                                            <div className="row">
                                                {programEdVideos && programEdVideos.map((data, key) => {
                                                    if (data) {
                                                        return (
                                                            <div className="col-12 col-sm-12 col-md-6  col-lg-4 col-xl-3 " key={key}>
                                                                <div className="test_videobox-education pt-4">
                                                                    <div className="check_test pb-2">
                                                                        <CustomVideoPlayer videoUrl={data?.video ? data?.video : ''} className={'video'} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                            <div className="pagination">
                                                <span className="itemsPerPagelabel">Items Per Page:</span>
                                                <div className={'perpagedropdown'}>
                                                    <select id="itemsPerPage" value={itemsEdPerPage} onChange={handleItemsEdPerPageChange}>
                                                        <option value={4}>4</option>
                                                        <option value={8}>8</option>
                                                        <option value={12}>12</option>
                                                        <option value={16}>16</option>
                                                        <option value={20}>20</option>
                                                    </select>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                                </div>
                                                <span className="totalpages">{currentEdPage}-{totalEdPages} of {totalEdPages}</span>
                                                <div className="paginationbuttons">
                                                    {currentEdPage > 1 ? <button
                                                        className={'button-previous'}
                                                        onClick={() => setCurrentEdPage(currentEdPage - 1)}
                                                        disabled={currentEdPage === 1}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                                    </button> : ''}
                                                    {currentEdPage < totalEdPages ? <button
                                                        className={'button-next'}
                                                        onClick={() => setCurrentEdPage(currentEdPage + 1)}
                                                        disabled={currentEdPage === totalEdPages}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                                    </button> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                            }
                        </>

                    )}
                </div>
            </section >
            <Footer />
        </>
    );
}

const mapStateToProps = state => {
    return {
        educational_video: state.programReducer.educational_video,
        program: state.programReducer.program,
        EducationVideosFailure: state.programReducer.EducationVideosFailure || null,
        EducationVideosRequest: state.programReducer.EducationVideosRequest || null,
        EducationVideosSuccess: state.programReducer.EducationVideosSuccess || null,
    }
}
export default connect(mapStateToProps, { getEducationalVideos, getProgram })(EducationVideo)