import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { FadeLoader } from "react-spinners"
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, loginWithGoogle } from '../../../actions/auth';
import axios from 'axios';
import { LoginMain } from '../../../styles/common.style';
import { GoogleOAuthProvider, GoogleLogin, hasGrantedAnyScopeGoogle } from '@react-oauth/google';
import { googleLoginCredential } from "../../../utils/constants";
import FacebookLogin from "react-facebook-login";

const Login = ({ login, loginWithGoogle, isAuthenticated, loading, isVerified, loginSuccess }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;
  //const GOOGLE_AUTH_URL = 'https://apistaging.mybodysolutions.com/api/v1/google/redirect';
  const FACEBOOK_AUTH_URL = 'https://apistaging.mybodysolutions.com/api/v1/facebook/redirect';
  const GOOGLE_CLIENT_ID = '559668091821-hl4e83cs4oqhb8cedoa7ii5r2ehl3oij.apps.googleusercontent.com'
  const GOOGLE_CLIENT_CALLBACK = 'https://staging.mybodysolutions.com/auth/callback'

  const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_CLIENT_CALLBACK}&response_type=code&scope=email%20profile`;

  const navigate = useNavigate()

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  }
  if (loginSuccess && isAuthenticated && isVerified) {
    navigate("/welcome", { replace: true })
  }
  if (loginSuccess && !isVerified) {
    navigate("/verify-email", { state: { email } })
  }
  if (!loginSuccess) {
    loading = false
  }
  const facebookLogin = async () => {
    window.open(FACEBOOK_AUTH_URL, '_self');
  }
  const googleLogin = async () => {
    console.log("google login");
    console.log(typeof loginWithGoogle); // Should output 'function'

    // window.location.href = GOOGLE_AUTH_URL;
    window.open(GOOGLE_AUTH_URL, '_self');

    //loginWithGoogle();
    // axios.get('https://apistaging.mybodysolutions.com/api/v1/google/redirect', {
    //     headers: {
    //         'Content-Type': 'application/json', 
    //         'Access-Control-Allow-Origin': '*', 
    //     },
    //     withCredentials: true,
    // })
    //     .then(response => {
    //         console.log(response);
    //     })
    //     .catch(err => {
    //         console.error('CORS error or other issue:', err);
    //     });

  };

  const handleSuccess = (credentialResponse) => {
    const token = credentialResponse.credential; // The JWT token
    loginWithGoogle(token, "google")

  };

  const handleError = () => {
    console.log('Login Failed');
  };

  const responseFacebook = (response) => {
    console.log("Facebook Login Response:", response);
    if (response.accessToken) {
      loginWithGoogle(response.accessToken, "facebook")
    }
  };

  return (
    <>
      <LoginMain>
        <Form onSubmit={onSubmit} id='call_form' className='form_signup'>
          <div className='sign'>
            <h4>Log In</h4>
            <p>
              Welcome to Body Solutions! Please enter your
              <br /> details to Set up your account
            </p>
          </div>
          <Form.Group className='mb-3 form_signup'>
            <Form.Label>Email address</Form.Label>

            <div className='position-relative email_ic'>
              <Form.Control
                type='email'
                placeholder='Email Address '
                className='form-1 email_img'
                name='email'
                value={email}
                onChange={onChange}
                required
              />
            </div>
            {/* <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text> */}
          </Form.Group>

          <Form.Group className='mb-3 form_signup'>
            <Form.Label>Password</Form.Label>

            <div className='position-relative password_ic'>
              <Form.Control
                type='password'
                placeholder='Type Password'
                className='form-1 pass_img'
                value={password}
                onChange={onChange}
                name='password'
                required
              />
            </div>
          </Form.Group>
          <Form.Group className='form-group loader_custom'>
            <div className='forgot already'>
              <Link to='/forgot-password'>Forgot Password?</Link>
            </div>
            {loading ? (
              <FadeLoader color={'#FB1417'} height={10} />
            ) : (
              <Form.Control type='submit' value='sign In' className='submit-btn w-100 pb-3' required />
            )}
            <p className='already'>
              Don’t have an account? <Link to='/signup'>Sign Up</Link>
            </p>
          </Form.Group>

          {/* <Form.Group className="mb-3" >
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group>
        <Button variant="secondary" type="submit">
          Submit
        </Button> */}
        </Form>
        {/* <button type='button' onClick={googleLogin}>
          Login with Google
        </button>
        <button type='button' onClick={facebookLogin}>
          Login with Facebook
        </button> */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', margin: '20px' }}>
          <GoogleOAuthProvider clientId={googleLoginCredential.clientId}>
            <GoogleLogin
              onSuccess={handleSuccess}
              onError={handleError}
              scope="https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
            />
          </GoogleOAuthProvider>

          <FacebookLogin
            appId="471605882666485"
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            textButton="Login with Facebook"
            onFailure={(error) => console.error('Facebook Login Error:', error)}
            cssClass="custom-facebook-button"
          />
        </div>

      </LoginMain>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  loginWithGoogle: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isVerified: PropTypes.bool,
  loginSuccess: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  isVerified: state.auth.isVerified,
  loginSuccess: state.auth.loginSuccess,

});

export default connect(mapStateToProps, { login, loginWithGoogle })(Login);
