import React from 'react'
import { connect } from 'react-redux'


const StaticPage = ({ }) => {
    return (
        <>
            <h1>Static Page</h1>
        </>

    );
}

StaticPage.propTypes = {};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(StaticPage);