import React, { useEffect, useState } from "react";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Home/Header";
import Header2 from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Container, Row, Col, Form } from "react-bootstrap";
import imgNotFound from "../../images/broken-hd.png";
import imgRedirect from "../../images/redirect.png";
import { getMusclesCategories } from "../../../actions/user_program";
import { connect } from "react-redux";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import profile from "../../images/Group 29358.png";
import { cross_origin, SOCIAL_MEDIA_LINKS } from "../../../utils/constants";
import FadeLoader from "react-spinners/FadeLoader";
import { Interweave } from "interweave";
import Modal from "react-bootstrap/Modal";

const MusclesCategory = ({ MusclesCategoriesSuccess, getMusclesCategories, musclesCategories }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [loader, setLoader] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const maxLength = 40;
  const [productDetails, setProductDetails] = useState([]);

  const [isSearch, setIsSearch] = useState(false);
  const [musclesAnatomyData, setMusclesAnatomyData] = useState([]);

  useEffect(() => {
    getMusclesCategories();
  }, []);

  useEffect(() => {
    if (musclesCategories) {
      // setMusclesCategory(musclesCategories);
      // setMusclesTempCategory(musclesCategories);

      setLoader(false);
    }
  }, [musclesCategories]);

  useEffect(() => {
    if (musclesAnatomyData) {
      setLoader(false);
    }
  }, [musclesAnatomyData]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, []);

  const handleSearchClick = (value) => {
    // if (value.length == 0) {
    setLoader(true);
    // getMusclesCategories(value);
    if (value.length > 0) {
      setIsSearch(true);
      if (musclesCategories && musclesCategories.length > 0) {
        const filteredData = musclesCategories
          .map((group) => group.musclesAnatomy || [])
          .reduce((acc, muscles) => acc.concat(muscles), [])
          .filter((muscle) =>
            muscle.title.toLowerCase().includes(value.toLowerCase())
          );

        setLoader(false);
        setMusclesAnatomyData(filteredData);
      } else {
        setLoader(false);
        setMusclesAnatomyData([]);
      }
    } else {
      setLoader(false);
      setIsSearch(false);
    }
    // setCurrentPage(1)
  };

  const handleSearch = (value) => {
    // setVal(true);
    setKeyword(value);
    if (value.length == 0) {
      getMusclesCategories();
    }
  };

  const handleReadMoreClick = (data) => {
    setProductDetails(data);
    setModalShow(true);
  };

  const handleProducts = (data) => {
    navigate("/muscles-anatomy/library", {
      state: { muscles_anatomy: data?.musclesAnatomy },
    });

    // history.push({
    //   pathname: "/summary-excercise/" + id,
    //   state: {
    //     products: data,
    //   },
    // });
  };

  const ReadMoreP = ({ text, maxLength, className }) => {
    const [expanded, setExpanded] = useState(false);
    const truncatedText = text?.slice(0, maxLength);
    return (
      <div>
        <div
          className={`titles-label ${className}`}
          style={{ wordWrap: "break-word" }}
        >
          <Interweave content={expanded ? text : truncatedText} />
          <button
            className={"btn-expand float-right"}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Read less" : "Read more"}
          </button>
        </div>
      </div>
    );
  };

  const ProductCard = (data) => {
    const productData = data?.data;
    return (
      // <div className={"category-box"} onClick={() => handleProducts(productData)} style={{ "cursor": "pointer" }}>

      //     <div className={"mt-2"}>
      //         <div className={"product-info d-flex flex-column text-center"}>
      //             <h3>
      //                 <b>{productData?.name}</b>
      //             </h3>
      //         </div>

      //     </div>
      // </div>
      <div
        className={"category-box"}
        onClick={() => handleProducts(productData)}
      >
        <div className={"mt-2"}>
          <div className={"product-info d-flex flex-column text-center"}>
            <h3>
              <b>{productData?.name}</b>
            </h3>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ScrollTopOnMount />
      {user ? <Header2 /> : <Header />}
      <div className={"clearfix"}></div>

      <section className={"recomended-products"}>
        <Container>
          <Row>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="videos_headings">
                <h2 className="detail_span" style={{ color: "#fff" }}>
                  MUSCLE LIBRARY
                </h2>
                {/* <p style={{ color: "#fff" }}>
                                    Our favorite products used in the Body Solutions Programs
                                </p> */}
              </div>
            </div>
          </Row>
          <div className={"clearfix mt-5"}></div>
          <div className="col-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <Form
              className="d-flex mt-3 mb-3"
              onSubmit={(e) => e.preventDefault()}
            >
              <Form.Control
                value={keyword}
                type="search"
                placeholder="Search"
                className="me-2 rounded-pill"
                aria-label="Search"
                onChange={(e) => {
                  handleSearch(e.target.value);
                  handleSearchClick(e.target.value);
                }}
              />
            </Form>
          </div>
         
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <div className="row">
              { !MusclesCategoriesSuccess ? (
                <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 ">
                  <div className={"empty-page-loader"}>
                    <div className="spin-loader">
                      <FadeLoader color={"#EC1246"} height={10} />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {!isSearch ? (
                    <div
                      className="justify-content-center g-5 w-50 mx-auto mb-5"
                      style={{ gap: "20px" }}
                    >
                      <div
                        className="d-flex justify-content-sm-center justify-content-xl-start flex-wrap"
                        style={{ gap: "10px" }}
                      >
                        {musclesCategories &&
                          musclesCategories.map((category, key) => {
                            if (category) {
                              return <ProductCard data={category} key={key} />;
                            }
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="row">
                        {musclesAnatomyData?.map((category, key) => {
                          return (
                            <div
                              className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 common-box-height "
                              key={key}
                            >
                              <Link
                                className={"nav-link library-nav"}
                                data-toggle="tab"
                                to={"/muscles-anatomy/detail/" + category?.id}
                                role="tab"
                              >
                                <div class="library-img-container">
                                  <div class="library-img-container-inner">
                                    <div>
                                      <div class="title-one">
                                        {category?.title}
                                      </div>
                                      <div class="title-two">
                                        {category?.title2}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="library-img-box">
                                    <img
                                      src={
                                        category?.image ? category?.image : ""
                                      }
                                      alt={
                                        category?.title ? category?.title : ""
                                      }
                                      crossOrigin={
                                        cross_origin ? "anonymous" : null
                                      }
                                    />
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={"clearfix mt-5"}></div>
          {/* <Row className={"mt-5"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mx-auto"}>
                            <div className="social-links">
                                <div className="kit">
                                    <h3>Share this kit:</h3>
                                    <ul className="links-icons">
                                        <li>
                                            <a href={SOCIAL_MEDIA_LINKS.TWITTER} target="_blank">
                                                <AiOutlineTwitter />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={SOCIAL_MEDIA_LINKS.FACEBOOK} target="_blank">
                                                <FaFacebookF />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={SOCIAL_MEDIA_LINKS.TIKTOK} target="_blank">
                                                <FaTiktok />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="social-profile">
                                    <div className="wrapper">
                                        <img src={profile} alt="profile" className="img-fluid" />
                                    </div>
                                    <div className="content">
                                        <h3>@bodysolutions</h3>
                                        <a
                                            href={SOCIAL_MEDIA_LINKS.WEB}
                                            target="_blank"
                                            className="btn-follow"
                                        >
                                            Follow
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
        </Container>
      </section>
      <Footer />

      {modalShow && (
        <>
          <Modal
            show={modalShow}
            onHide={handleClose}
            id="IntroModal"
            className="modal fade"
            aria-hidden="true"
            aria-labelledby="exampleModalLabel"
            size="xl"
            centered
          >
            <Modal.Header>
              <Modal.Title>{productDetails?.title}</Modal.Title>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className={"justify-text"}>
                {productDetails?.description ? (
                  <>
                    <h6>Description:</h6>
                    <div>{productDetails?.description}</div>
                  </>
                ) : (
                  <p>No description found.</p>
                )}
                {productDetails?.note ? (
                  <>
                    <div className="mt-3">
                      <h6>Comments:</h6>
                      {productDetails?.note}
                    </div>
                  </>
                ) : (
                  <p>No comments found.</p>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    musclesCategories: state.programReducer.musclesCategories,
    MusclesCategoriesSuccess: state.programReducer.MusclesCategoriesSuccess || null,

  };
};
export default connect(mapStateToProps, { getMusclesCategories })(
  MusclesCategory
);
