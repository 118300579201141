import styled from "styled-components";

export const LoginMain = styled.div`
  position: relative;
  height: 100vh;
  background-color: #fff;
  .login-main-block {
    display: flex;
    align-items: center;
    padding: 50px;
    position: relative;
    height: 100%;
    .login-main-block-left {
      width: 60%;
      padding: 70px 100px;
      position: relative;
      height: 100%;
      .login-main-block-left-link {
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .login-main-block-left-banner {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .title-block {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 0);
        h1 {
          font-size: 40px;
          line-height: 40px;
          font-weight: 600;
        }
      }
    }
    .login-main-block-right {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      .login-form-block {
        width: 500px;
        .login-form-block-title {
          text-align: center;
          h2 {
            color: #c00000;
            font-size: 40px;
            line-height: 50px;
            margin-bottom: 40px;
            font-weight: 600;
          }
        }
        .form-main {
          position: relative;
          .form_signup {
            position: relative;
            margin-bottom: 30px;
            padding: 0px;
            input {
              width: 100%;
              height: 50px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 10px;
              padding: 10px 10px 10px 55px;
              font-family: 'Mulish', serif;
              color: rgba(0, 0, 0, 0.3);
              font-size: 18px;
              line-height: 18px;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
              &::placeholder {
                color: rgba(0, 0, 0, 0.3);
              }
            }
            .form-sign-icon {
              position: absolute;
              top: 15px;
              left: 20px;
            }
          }
          .forgot-link {
            margin-bottom: 10px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            a {
              color: rgba(0, 0, 0, 0.5);
              font-size: 18px;
              line-height: 20px;
              font-weight: 400;
              font-family: 'Mulish', serif;
              &:hover {
                color: #c00000;
              }
            }
          }
          .bottom-auth-block {
            padding: 40px 50px 10px 50px;
            .common-btn-main {
              .btn-common-auth {
                width: 100%;
                border-color: #c00000;
                border-radius: 10px;
                background-color: #c00000;
                padding: 13px;
                font-size: 20px;
                line-height: 24px;
                font-family: 'Poppins', serif;
                font-weight: 600;
                color: #fff;
                &:hover {
                  background: transparent;
                  color: #c00000;
                }
              }
            }
            .sign-text-block {
              font-size: 20px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.5);
              font-family: 'Poppins', serif;
              text-align: center;
              padding-top: 40px;
              a {
                color: #c00000;
                font-weight: 600;
              }
            }
          }
          .social-login-block {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 40px;
            .social-login-block-inner {
              padding: 0px 8px;
              .social-login-btn {
                width: 100px;
                border-radius: 10px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                background: transparent;
                outline: none;
              }
            }
          }
        }
      }
    }
  }
`;