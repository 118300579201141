import Carousel from "react-bootstrap/Carousel";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  getExerciseDetail,
  getProgram,
  getWeekSchedule,
  updateProgramDayStatus,
  // getEducationalVideos
} from "../../../actions/user_program";
import { FadeLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import Exercises from "./Exercises";
import BreadcrumbMenu from "./BreadcrumbMenu";
import { UPDATE_PROGRAM_STATUS_PURGE } from "../../../actions/types";
import { RiMiniProgramLine } from "react-icons/ri";
import CustomVideoPlayer from '../AssessmentTimer/CustomVideoPlayer';


const WeekSlider = ({
  weekScheduleRequest,
  weekScheduleSuccess,
  weekScheduleFailure,
  programId,
  getWeekSchedule,
  getProgram,
  weekSchedule,
  getExerciseDetail,
  exercises,
  updateProgramDayStatus,
  userProgramDayStatusUpdateSuccess,
  start,
  setStart,
  educational_video,
  // getEducationalVideos
}) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [activeDayId, setActiveDayId] = useState(null);
  const [activeDayName, setActiveDayName] = useState(null);
  const [resume, setResume] = useState(false);
  const [view, setView] = useState(false);
  const [programEdVideos, setProgramEdVideos] = useState(null)
  const [itemsEdPerPage, setItemsEdPerPage] = useState(8);
  const [currentEdPage, setCurrentEdPage] = useState(educational_video ? parseInt(educational_video.meta.current_page) : 1);
  const [totalEdPages, setEdTotalPages] = useState(0)

  // useEffect(() => {
  // getEducationalVideos(id, currentEdPage, itemsEdPerPage, 1);

  //   if (educational_video?.data) {
  //     const uniqueItems = filterUniqueItems(educational_video.data);
  //     console.log(uniqueItems, "uniqueItems")
  //   }
  // }, [currentEdPage, itemsEdPerPage]);

  // useEffect(() => {
  //   setEdTotalPages(educational_video ? parseInt(educational_video.meta.last_page) : 1)
  //   setProgramEdVideos(educational_video ? educational_video.data : [])
  // }, [educational_video]);

  // const filterUniqueItems = (arr) => {
  //   const uniqueIds = new Set();

  //   const filteredArray = arr.filter((item) => {
  //     if (!uniqueIds.has(item.video)) {
  //       uniqueIds.add(item.video);
  //       return true;
  //     }
  //     return false;
  //   });

  //   return filteredArray;
  // };

  useEffect(() => {
    getWeekSchedule(id);
  }, []);

  useEffect(() => {
    let activeEx = exercises?.filter((item, index) => item.is_started == true);

    setResume(activeEx?.length > 0 ? true : false);
  }, [exercises]);

  useEffect(() => {
    if (activeDayId != null && activeDayId != "" && activeDayId != undefined) {
      let days =
        weekSchedule && weekSchedule[0]["days"] ? weekSchedule[0]["days"] : [];
      localStorage.setItem("activeDayId", activeDayId);
      console.log(activeDayName, "activeDayName");
      let nextActiveDayIndex = days.findIndex(
        (item, index) => item.id === activeDayId
      );
      let nextActiveDay = days[nextActiveDayIndex + 1];
      if (nextActiveDay !== undefined && nextActiveDay !== null) {
        localStorage.setItem("nextActiveDay", nextActiveDay.id);
      }
      getExerciseDetail(activeDayId);
    }
  }, [activeDayId]);

  useEffect(() => {
    if (
      weekSchedule != null &&
      weekSchedule != "" &&
      weekSchedule != undefined
    ) {
      let days =
        weekSchedule && weekSchedule[0]["days"] ? weekSchedule[0]["days"] : [];
      let activeDay = days.filter((item, index) => item.is_active == 1);
      setActiveDayName(
        activeDay && activeDay[0] != null && activeDay[0] != undefined
          ? "Day " + activeDay[0]["programDay"]["day"]
          : weekSchedule[0]["days"]
            ? "Day " + weekSchedule[0]["days"][0]["programDay"]["day"]
            : ""
      );
      setActiveDayId(
        activeDay && activeDay[0] != null && activeDay[0] != undefined
          ? activeDay[0].id
          : weekSchedule && weekSchedule[0]["days"]
            ? weekSchedule[0]["days"][0].id
            : ""
      );
      localStorage.setItem("nextActiveDayId", activeDayId);
    }
  }, [weekSchedule]);

  const handleItemsEdPerPageChange = (e) => {
    setItemsEdPerPage(parseInt(e.target.value));
  };

  const changeDayId = (id, program_id, day) => {
    setActiveDayId(id);

    let dt = {
      is_active: 1,
      program_id: program_id,
    };
    updateProgramDayStatus(dt, id);
    localStorage.setItem("activeDayName", `Day ${day}`);
  };

  if (weekScheduleFailure) {
    navigate("/welcome", { replace: true });
  } else if (!weekScheduleSuccess) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 center-loader">
            <FadeLoader color={"#FB1417"} height={10} />
          </div>
        </div>
      </div>
    );
  }

  if (userProgramDayStatusUpdateSuccess) {
    dispatch({ type: UPDATE_PROGRAM_STATUS_PURGE });
    getWeekSchedule(id);
  }


  console.log("exercises", exercises);
  return (
    <>
      <section className="assestmentgrid_001">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12 p-0">
              <Carousel
                className="box_week"
                indicators={false}
                nextLabel=""
                prevLabel=""
                interval={null}
              >
                {weekSchedule?.map((item, key) => (
                  <Carousel.Item
                    style={{ textAlign: "center" }}
                    key={item.week}
                  >
                    <h6>Week {item.week}</h6>
                    <div className="box_date">
                      <ul className="date_week">
                        {item.days.map((day) => (
                          <li key={day?.id}>
                            <p
                              className={
                                day?.is_active === 1 || activeDayId === day?.id
                                  ? "active"
                                  : ""
                              }
                              data-toggle="modal"
                              data-target="#statusCheckout1"
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                onClick={() =>
                                  changeDayId(
                                    day?.id,
                                    day?.program_id,
                                    day?.programDay.day
                                  )
                                }
                              >
                                Day {day?.programDay.day}
                              </span>
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <BreadcrumbMenu
        setView={setView}
        activeDayId={
          activeDayId ? activeDayId : localStorage.getItem("activeDayId")
        }
        lastActiveDayId={resume}
      />
      <Exercises
        activeDayId={
          activeDayId ? activeDayId : localStorage.getItem("activeDayId")
        }
        programId={id ? id : null}
        view={view}
        exercises={exercises}
        setStart={setStart}
        start={start}
      />
      {/* <div className="container">
        {programEdVideos && programEdVideos.length > 0 ?
          <div className="row mt-4 mb-5">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
              <div className="videos_headings">
                <h2 className="detail_span" style={{ color: "#fff" }}>EDUCATIONAL VIDEOS</h2>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="row">
                {programEdVideos && programEdVideos.map((data, key) => {
                  if (data) {
                    return (
                      <div className="col-12 col-sm-12 col-md-6  col-lg-4 col-xl-3 " key={key}>
                        <div className="test_videobox-education pt-4">
                          <div className="check_test pb-2">
                            <CustomVideoPlayer videoUrl={data?.video ? data?.video : ''} className={'video'} />
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
                }
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
              <div className="pagination">
                <span className="itemsPerPagelabel">Items Per Page:</span>
                <div className={'perpagedropdown'}>
                  <select id="itemsPerPage" value={itemsEdPerPage} onChange={handleItemsEdPerPageChange}>
                    <option value={4}>4</option>
                    <option value={8}>8</option>
                    <option value={12}>12</option>
                    <option value={16}>16</option>
                    <option value={20}>20</option>
                  </select>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                </div>
                <span className="totalpages">{currentEdPage}-{totalEdPages} of {totalEdPages}</span>
                <div className="paginationbuttons">
                  {currentEdPage > 1 ? <button
                    className={'button-previous'}
                    onClick={() => setCurrentEdPage(currentEdPage - 1)}
                    disabled={currentEdPage === 1}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                  </button> : ''}
                  {currentEdPage < totalEdPages ? <button
                    className={'button-next'}
                    onClick={() => setCurrentEdPage(currentEdPage + 1)}
                    disabled={currentEdPage === totalEdPages}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                  </button> : ''}
                </div>
              </div>
            </div>
          </div>
          : ''}
      </div> */}
    </>
  );
};

WeekSlider.propTypes = {
  weekSchedule: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  weekScheduleRequest: state.programReducer.weekScheduleRequest || null,
  weekScheduleSuccess: state.programReducer.weekScheduleSuccess || null,
  weekScheduleFailure: state.programReducer.weekScheduleFailure || null,
  userProgramDayStatusUpdateSuccess:
    state.programReducer.userProgramDayStatusUpdateSuccess || null,
  programId: state.programReducer.programId,
  weekSchedule: state.programReducer.weekSchedule,
  exercises: state.programReducer.exercises,
  educational_video: state.programReducer.educational_video,
});

export default connect(mapStateToProps, {
  // getEducationalVideos,
  getWeekSchedule,
  getProgram,
  getExerciseDetail,
  updateProgramDayStatus,
})(WeekSlider);

// export default WeekSlider
