import React, { useEffect, useState } from "react";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Home/Header";
import Header2 from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Container, Row, Col, Form } from "react-bootstrap";
import imgNotFound from "../../images/broken-hd.png";
import imgRedirect from "../../images/redirect.png";
import { getCategories } from "../../../actions/user_program";
import { connect } from "react-redux";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaTiktok, FaComment, FaFacebookF } from "react-icons/fa";
import profile from "../../images/Group 29358.png";
import { cross_origin, SOCIAL_MEDIA_LINKS } from "../../../utils/constants";
import FadeLoader from "react-spinners/FadeLoader";
import { Interweave } from "interweave";
import Modal from "react-bootstrap/Modal";

const ProductsWithCategory = ({ getCategories, categories }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [loader, setLoader] = useState(true);

  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const maxLength = 40;

  const [productDetails, setProductDetails] = useState([]);

  const [isSearch, setIsSearch] = useState(false);
  const [productsData, setProductsData] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (categories) {
      setLoader(false);
    }
  }, [categories]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, []);

  const handleSearchClick = (value) => {
    //getCategories(keyword);
    setLoader(true);
    if (value.length > 0) {
      setIsSearch(true);
      if (categories && categories.length > 0) {
        const filteredData = categories
          .map((group) => group.equipment || [])
          .reduce((acc, muscles) => acc.concat(muscles), [])
          .filter((muscle) =>
            muscle.title.toLowerCase().includes(value.toLowerCase())
          );

        setLoader(false);
        setProductsData(filteredData);
      } else {
        setLoader(false);
        setProductsData([]);
      }
    } else {
      setLoader(false);
      setIsSearch(false);
    }
    // setCurrentPage(1)
  };

  const handleSearch = (value) => {
    // setVal(true);
    setKeyword(value);
    if (keyword && value.length == 0) {
      getCategories();
    }
  };

  const handleReadMoreClick = (data) => {
    setProductDetails(data);
    setModalShow(true);
  };

  const handleProducts = (data) => {
    navigate("/recommended-products", { state: { products: data.id } });
    // navigate(`/recommended-products/${data.id}`, { replace: true });

    // history.push({
    //   pathname: "/summary-excercise/" + id,
    //   state: {
    //     products: data,
    //   },
    // });
  };

  const ReadMoreP = ({ text, maxLength, className }) => {
    const [expanded, setExpanded] = useState(false);
    const truncatedText = text?.slice(0, maxLength);
    return (
      <div>
        <div
          className={`titles-label ${className}`}
          style={{ wordWrap: "break-word" }}
        >
          <Interweave content={expanded ? text : truncatedText} />
          <button
            className={"btn-expand float-right"}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Read less" : "Read more"}
          </button>
        </div>
      </div>
    );
  };

  const ProductCard = (data) => {
    const productData = data?.data;
    return (
      <div
        className={"product-box"}
        onClick={() => handleProducts(productData)}
        style={{ cursor: "pointer" }}
      >
        <div className={"mx-auto text-center"}>
          <img
            className={"img-fluid product-img"}
            crossOrigin={cross_origin ? "anonymous" : null}
            src={productData?.image ? productData?.image : imgNotFound}
            alt={productData?.title}
            title={data?.title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = imgNotFound;
            }}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <div className={"mt-3 p-2"}>
          <div className={"product-info d-flex flex-column text-center"}>
            <h3>
              <b>{productData?.name}</b>
            </h3>
            {/* <p>
              <span>{productData?.description}</span>
            </p> */}
          </div>

          {/* <div className="clearfix mt-3"></div> */}
        </div>
      </div>
    );
  };

  const ProductCardDetails = (data) => {
    const productData = data?.data;
    const referral_link2 = productData?.referral_link2
      ? JSON.parse(productData?.referral_link2)
      : "";
    return (
      <div className={"product-box"}>
        <div className={"mx-auto text-center"}>
          <img
            className={"img-fluid product-img"}
            crossOrigin={cross_origin ? "anonymous" : null}
            src={productData?.image ? productData?.image : imgNotFound}
            alt={productData?.title}
            title={data?.title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = imgNotFound;
            }}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <div className={"mt-3 p-2"}>
          <div className={"product-info d-flex flex-column"}>
            <h4 className={"text-uppercase"}>{productData?.title}</h4>
            {productData?.description != "" ? (
              <>
                <div className={"product-desc"}>{productData?.description}</div>
                <div className={"float-right"}>
                  <button
                    className={"btn-expand-read float-right"}
                    onClick={() => handleReadMoreClick(productData)}
                  >
                    Read more
                  </button>
                </div>
              </>
            ) : (
              <div className={"comment-not-found"}>No description found.</div>
            )}
            {/* <p>
              <span>{productData?.description}</span>
            </p> */}
          </div>
          {/* <button
            className={"btn-expand-read float-right"}
            onClick={() => handleReadMoreClick(productData)}
          >
            Read more
          </button> */}
          <div className="clearfix mb-2">
            {productData?.note != null &&
            productData?.note != undefined &&
            productData?.note != "" &&
            productData?.note != " " ? (
              <div className="mt-4">
                <div
                  className={"product-comments"}
                  onClick={() => handleReadMoreClick(productData)}
                >
                  {productData?.note && <FaComment />}{" "}
                  <span>{productData?.note}</span>
                </div>
                <button
                  className={"btn-expand-read float-right"}
                  onClick={() => handleReadMoreClick(productData)}
                >
                  Read more
                </button>
              </div>
            ) : (
              <>
                <div className={"comment-not-found"}>No comment found.</div>
                {/* <button
                className={"btn-expand-read float-right"}
                onClick={() => handleReadMoreClick(productData)}
              >
                Read more
              </button> */}
              </>
            )}
          </div>
          {/* <p>
            <span>{productData?.note}</span>
          </p> */}

          <div className={"mx-auto text-center"}>
            <span>Buy it On</span>
            <div className="logo-grid">
              {referral_link2 &&
                referral_link2.map((link, key) => (
                  <>
                    {link?.id === 1 && link?.inputValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img className="logo-imgage" src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 2 && link?.inputValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img className="logo-imgage" src={link?.logo} />
                      </a>
                    )}

                    {link?.id === 3 && link?.inputValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img className="logo-imgage" src={link?.logo} />
                      </a>
                    )}

                    {link?.id === 4 && link?.inputValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img className="logo-imgage" src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 5 && link?.inputValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img className="logo-imgage" src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 6 && link?.inputValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img className="logo-imgage" src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 7 && link?.inputValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img className="logo-imgage" src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 8 && link?.inputValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img className="logo-imgage" src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 9 && link?.inputValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img className="logo-imgage" src={link?.logo} />
                      </a>
                    )}
                    {link?.id === 10 && link?.inputValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img className="logo-imgage" src={link?.logo} />
                      </a>
                    )}
                  </>
                ))}
            </div>
            {/* <a
              href={productData?.referral_link}
              target="_blank"
              className={"btn-full"}
            >
              {productData?.referral_link_image ? (
                <img
                  className={"img-fluid"}
                  crossOrigin={cross_origin ? "anonymous" : null}
                  src={productData?.referral_link_image}
                  alt={"Recommended Vendor"}
                  title={"Recommended Vendor"}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = imgRedirect;
                  }}
                />
              ) : (
                "Click Here"
              )}
            </a> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ScrollTopOnMount />
      {user ? <Header2 /> : <Header />}
      <div className={"clearfix"}></div>

      <section className={"recomended-products"}>
        <Container>
          <Row>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="videos_headings">
                <h2 className="detail_span" style={{ color: "#fff" }}>
                  Product Recommendations
                </h2>
                <p style={{ color: "#fff" }}>
                  Our favorite products used in the Body Solutions programs
                </p>
              </div>
            </div>
          </Row>
          <div className={"clearfix mt-5"}></div>
          <div className="col-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <Form
              className="d-flex mt-3 mb-3"
              onSubmit={(e) => e.preventDefault()}
            >
              <Form.Control
                value={keyword}
                type="search"
                placeholder="Search"
                className="me-2 rounded-pill"
                aria-label="Search"
                onChange={(e) => {
                  handleSearch(e.target.value);
                  handleSearchClick(e.target.value);
                }}
              />
            </Form>
          </div>
          <Row>
            {loader ? (
              <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 ">
                <div className={"empty-page"}>
                  <div className="spin-loader">
                    <FadeLoader color={"#EC1246"} height={10} />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!isSearch ? (
                  <>
                    {categories &&
                      categories.map((category, key) => {
                        if (category) {
                          return (
                            <Col xs={12} sm={6} md={4} lg={4} xl={4} key={key}>
                              <ProductCard data={category} />
                            </Col>
                          );
                        }
                      })}
                  </>
                ) : productsData.length > 0 ? (
                  productsData &&
                  productsData.map((products, key) => {
                    if (products) {
                      return (
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} key={key}>
                          <ProductCardDetails data={products} />
                        </Col>
                      );
                    }
                  })
                ) : (
                  <div className="text-center">
                    <h3>No products found.</h3>
                  </div>
                )}
              </>
            )}
          </Row>
          {/* <Row className={"mt-5"}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mx-auto"}>
              <div className="social-links">
                <div className="kit">
                  <h3>Share this kit:</h3>
                  <ul className="links-icons">
                    <li>
                      <a href={SOCIAL_MEDIA_LINKS.TWITTER} target="_blank">
                        <AiOutlineTwitter />
                      </a>
                    </li>
                    <li>
                      <a href={SOCIAL_MEDIA_LINKS.FACEBOOK} target="_blank">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href={SOCIAL_MEDIA_LINKS.TIKTOK} target="_blank">
                        <FaTiktok />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="social-profile">
                  <div className="wrapper">
                    <img src={profile} alt="profile" className="img-fluid" />
                  </div>
                  <div className="content">
                    <h3>@bodysolutions</h3>
                    <a
                      href={SOCIAL_MEDIA_LINKS.WEB}
                      target="_blank"
                      className="btn-follow"
                    >
                      Follow
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row className="mt-5 align-items-center">
            <Col md={6} className="px-0">
              <div className="kit">
                <h3>Share this kit:</h3>
                <ul className="links-icons">
                  <li>
                    <a href={SOCIAL_MEDIA_LINKS.TWITTER} target="_blank">
                      <AiOutlineTwitter />
                    </a>
                  </li>
                  <li>
                    <a href={SOCIAL_MEDIA_LINKS.FACEBOOK} target="_blank">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a href={SOCIAL_MEDIA_LINKS.TIKTOK} target="_blank">
                      <FaTiktok />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6} className="px-0">
              <div className="social-profile">
                <div className="content">
                  <figure>
                    <img src={profile} alt="profile" className="img-fluid" />
                  </figure>
                  <div className="wrapper">
                    <h3>@bodysolutions</h3>
                  </div>
                  <a
                    href={SOCIAL_MEDIA_LINKS.WEB}
                    target="_blank"
                    className="btn-product-follow"
                  >
                    Follow
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />

      {modalShow && (
        <>
          <Modal
            show={modalShow}
            onHide={handleClose}
            id="IntroModal"
            className="modal fade"
            aria-hidden="true"
            aria-labelledby="exampleModalLabel"
            size="xl"
            centered
          >
            <Modal.Header>
              <Modal.Title>{productDetails?.title}</Modal.Title>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className={"justify-text"}>
                {productDetails?.description ? (
                  <>
                    <h6>Description:</h6>
                    <div>{productDetails?.description}</div>
                  </>
                ) : (
                  <p>No description found.</p>
                )}
                {productDetails?.note ? (
                  <>
                    <div className="mt-3">
                      <h6>Comments:</h6>
                      {productDetails?.note}
                    </div>
                  </>
                ) : (
                  <p>No comments found.</p>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    categories: state.programReducer.categories,
  };
};
export default connect(mapStateToProps, { getCategories })(
  ProductsWithCategory
);
