import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loginWithGoogle } from '../../actions/auth';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const GoogleCallback = ({ loginWithGoogle }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleGoogleCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
        console.log("code", code);
    
      if (!code) {
        console.error('Authorization code is missing');
        navigate('/login'); // Redirect to login if no code is found
        return;
      }

      try {
        await loginWithGoogle(code);
        // const response = await axios.get(`https://apistaging.mybodysolutions.com/api/v1/google/callback?code=${code}`);

        // const { token, user } = response.data;

        // localStorage.setItem('authToken', token);

        // Redirect to dashboard or another page
        // navigate('/dashboard');
        navigate("/welcome", { replace: true })
      } catch (error) {
        console.error('Error during Google login:', error.response?.data || error.message);
        navigate('/login');
      }
    };

    handleGoogleCallback();
  }, [navigate]);

  return <div>Processing Google Login...</div>;
};

GoogleCallback.propTypes = {
    loginWithGoogle: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { loginWithGoogle })(GoogleCallback);
