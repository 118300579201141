import { Interweave } from 'interweave'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../actions/alert'
import { LEVEL_CHANGE_PURGE } from '../../../actions/types'
import { getExerciseLevelsData, updateExerciseLevel } from '../../../actions/user_program'
import level1 from "../../images/level1.png"
import level2 from "../../images/level2.png"
import ChangeLevelModal from '../../Layout/ChangeLevelModal'
import { EXERCISE_TIMES } from '../../../utils/constants'
import PosterVideoPlayer from "../AssessmentTimer/PosterVideoPlayer";
import GeneralModal from "./GeneralModal";

const CompiledChangeLevel = ({ ownProps, exercisesLevels, getExerciseLevelsData, weekSchedule, updateExerciseLevel, levelUpdate, levelUpdateSuccess, redirectLink }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [level, setLevel] = useState(null);
    const [showStartModal, setShowStartModal] = useState(false);
    const [modalData, setModalData] = useState();
    const [modalTitle, setModalTitle] = useState();
    const maxLength = 40;
    let { exerciseId } = ownProps
    let { curLevel } = ownProps
    let { activeId } = ownProps
    let { programId } = ownProps
    let { compilationVideoId } = ownProps


    const [exerciseLevelsData, setExerciseLevelsData] = useState([])
    const [exerciseLevel1Equipments, setExerciseLevel1Equipments] = useState([])
    const [exerciseLevel2Equipments, setExerciseLevel2Equipments] = useState([])
    const [exerciseLevel3Equipments, setExerciseLevel3Equipments] = useState([])

    useEffect(() => {
        // Fallback to exerciseId if compilationVideoId is null
        console.log("curLevel", curLevel);
        console.log("exerciseId", exerciseId);

        const idToUse = compilationVideoId || exerciseId;
        if (idToUse && curLevel) {
            getExerciseLevelsData(idToUse)
        }
    }, [compilationVideoId, exerciseId, curLevel])

    useEffect(() => {
        setExerciseLevelsData([])
        setExerciseLevel1Equipments([])
        setExerciseLevel2Equipments([])
        setExerciseLevel3Equipments([])

        // if (exercisesLevels != null && exercisesLevels != undefined && exercisesLevels.level1 != undefined && exercisesLevels.level1.length > 0) {
        //     exercisesLevels.level1 = exercisesLevels.level1.filter(item => item.exercise_id !== exerciseId);
        // }
        // if (exercisesLevels != null && exercisesLevels != undefined && exercisesLevels.level2 != undefined && exercisesLevels.level2.length > 0) {
        //     exercisesLevels.level2 = exercisesLevels.level2.filter(item => item.exercise_id !== exerciseId);
        // }
        // if (exercisesLevels != null && exercisesLevels != undefined && exercisesLevels.level3 != undefined && exercisesLevels.level3.length > 0) {
        //     exercisesLevels.level3 = exercisesLevels.level3.filter(item => item.exercise_id !== exerciseId);
        // }

        // if (exercisesLevels != null && exercisesLevels != undefined && exercisesLevels.level1 != undefined && exercisesLevels.level1.length > 0) {

        //     if (exercisesLevels.level1[0].equipment_exercise_type == 0) {
        //         setExerciseLevelsData(exerciseLevelsData => [...exerciseLevelsData, exercisesLevels.level1[0]])
        //         setExerciseLevel1Equipments(exerciseLevel1Equipments => exercisesLevels.level1.filter((_, index) => index !== 0));

        //     } else {
        //         setExerciseLevel1Equipments(exerciseLevel1Equipments => exercisesLevels.level1);
        //     }
        // }
        // if (exercisesLevels != null && exercisesLevels != undefined && exercisesLevels.level2 != undefined && exercisesLevels.level2.length > 0) {

        //     if (exercisesLevels.level2[0].equipment_exercise_type == 0) {
        //         setExerciseLevelsData(exerciseLevelsData => [...exerciseLevelsData, exercisesLevels.level2[0]])
        //         setExerciseLevel2Equipments(exerciseLevel2Equipments => exercisesLevels.level2.filter((_, index) => index !== 0));
        //     } else {
        //         setExerciseLevel2Equipments(exerciseLevel2Equipments => exercisesLevels.level2);
        //     }
        // }
        // if (exercisesLevels != null && exercisesLevels != undefined && exercisesLevels.level3 != undefined && exercisesLevels.level3.length > 0) {
        //     if (exercisesLevels.level3[0].equipment_exercise_type == 0) {
        //         setExerciseLevelsData(exerciseLevelsData => [...exerciseLevelsData, exercisesLevels.level3[0]])
        //         setExerciseLevel3Equipments(exerciseLevel3Equipments => exercisesLevels.level3.filter((_, index) => index !== 0));
        //     } else {
        //         setExerciseLevel3Equipments(exerciseLevel3Equipments => exercisesLevels.level3);
        //     }
        // }
        console.log("exercisesLevels", exercisesLevels);

        if (exercisesLevels != null && exercisesLevels != undefined) {
            setExerciseLevelsData(exercisesLevels)
        }

    }, [exercisesLevels])

    const changeLevelFunc = (data) => {
        setLevel(data)
        handleShow()
    }

    const changeLevelModal = (data, title) => {
        setModalTitle(title)
        setModalData(data)
        setShowStartModal(true)
    }
    const setShowStartFunc = (data) => {
        setShowStartModal(data)
    }

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleSubmit = () => {
        if (level && exerciseId) {
            updateExerciseLevel(activeId, exerciseId, level)
        }
    }

    if (levelUpdateSuccess && levelUpdate) {
        dispatch({ type: 'LEVEL_CHANGE_PURGE' });
        if (redirectLink !== null && redirectLink !== undefined && redirectLink !== '') {
            navigate(`${redirectLink}`, { replace: true, state: { myData: activeId } });
        } else {
            navigate(`/program/${programId}`, { replace: true });
        }
    }

    const ReadMoreP = ({ text, maxLength, className }) => {
        const [expanded, setExpanded] = useState(false);
        const truncatedText = text?.slice(0, maxLength);
        return (
            <div>
                <div className={`titles-label ${className}`}>
                    <Interweave content={expanded ? text : truncatedText} />
                </div>

            </div>
        );
    }

    const handleClick = () => {
        if (redirectLink !== null && redirectLink !== undefined && redirectLink !== '') {
            navigate(`${redirectLink}`, { replace: true, state: { myData: activeId } });
        } else {
            navigate(`/program/${programId}`, { replace: true });
        }
    };

    const StartingPosition = () => {
        return (
            <div className={"ViewModalContact"}>
                <div>
                    <div className={`titles-label`}>
                        <Interweave content={modalData} />
                    </div>

                </div>
            </div>
        )
    }
    return (
        <>
            <section className="change_level_002">
                <div className="container-fluid">
                    <div className="row">
                        {exerciseLevelsData.length !== 0 &&
                            <>
                                <div className="col-12 col-lg-10 col-md-9 col-sm-8 level01 order-3 order-sm-2 order-md-1">
                                    <h4>Want to select a different level?</h4>
                                    <p>If you select an exercise, it will replace the original exercise in your program. </p>
                                    <p>You can change the exercise levels at any time.</p>
                                </div>
                                <div className="col-12 col-lg-2 col-md-3 col-sm-4 level01 order-2 order-md-2 order-sm-3 text-sm-end text-center">
                                    <div className="position-relative">
                                        <button type="button" className="theme_btn1" onClick={() => handleClick()}>Back</button>
                                    </div>
                                </div>
                            </>
                        }
                        {exerciseLevelsData.length > 0 && exerciseLevelsData.map((item, index) => (
                            <div className="col-12 col-md-3 col-lg-3 order-3" key={index}>
                                <ul className="checkbox_list pd-list">
                                    <li>
                                        <label className="container-checkbox" id={'ex-' + index}>
                                            <span className=" checkmark main_check">
                                                <div className="row">
                                                    <div className="col-12 col-lg-6 col-md-6">
                                                        <div className="box_levelimg box_levelimg2">
                                                            <PosterVideoPlayer videoUrl={item.exerciseLevel.video + '#t=' + item.exerciseLevel.video_start_time + ',' + item.exerciseLevel.video_end_time}
                                                                posterUrl={item.exerciseLevel.video_cover}
                                                                className={`${item.exerciseLevel.video_flip == true ? 'video flip-video' : 'video'}`}
                                                            />
                                                        </div>

                                                        {
                                                            exerciseId == item.exerciseLevel.exercise_id ?
                                                                <h4 className='mt-1 ml-3 error_msg'>Current Level</h4> : <div className="level-change-btn-container">
                                                                    <button type="button" className="level-change-btn w-100" onClick={() => changeLevelFunc(item.id)}>Change Level</button>
                                                                </div>
                                                        }

                                                    </div>
                                                    <div className="col-12 col-lg-6 col-md-6">
                                                        <div className={'change-level-info-wrapper'}>
                                                            <h6>{item.exerciseLevel.file_name ? item.exerciseLevel.file_name + ':' : ''} <span className={`level level${item.level}`}>Level {item.level}</span></h6>
                                                            {item.exerciseLevel.starting_position && <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.exerciseLevel.starting_position, "Starting Position")}>Starting Position</button>}
                                                            {item.exerciseLevel.movements && <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.exerciseLevel.movements, "Movement Instructions")}>Movement Instructions</button>}
                                                            {item.notes && <button type="button" className="changeLevelBtn" onClick={() => changeLevelModal(item.notes, "Notes")}>Notes</button>}
                                                            {item.metric == 10 ? <p><span className="ten">Reps: </span> {item.exerciseLevel.reps}</p> : ''}
                                                            {item.metric == 20 ? <p><span className="ten">Time: </span> {EXERCISE_TIMES[item.exerciseLevel.time]}</p> : ''}

                                                        </div>

                                                    </div>
                                                </div>
                                            </span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </section >
            <GeneralModal title={modalTitle} show={showStartModal} onHide={() => setShowStartFunc(false)} size={"md"} content={<StartingPosition />} />
            <ChangeLevelModal handleClose={handleClose} show={show} handleSubmit={handleSubmit} />
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    exerciseLevelsRequest: state.programReducer.exerciseLevelsRequest || null,
    exerciseLevelsSuccess: state.programReducer.exerciseLevelsSuccess || null,
    exerciseLevelsFailure: state.programReducer.exerciseLevelsFailure || null,
    exercisesLevels: state.programReducer.exercisesLevels,

    levelUpdateRequest: state.programReducer.levelUpdateRequest || null,
    levelUpdateSuccess: state.programReducer.levelUpdateSuccess || null,
    levelUpdateFailure: state.programReducer.levelUpdateFailure || null,
    levelUpdate: state.programReducer.levelUpdate,
    ownProps: ownProps
});

export default connect(mapStateToProps, { getExerciseLevelsData, updateExerciseLevel })(CompiledChangeLevel);