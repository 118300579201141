import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Header from "../../Layout/Header";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Footer from "../../Layout/Footer";
import { getMusclesAnatomy } from "../../../actions/user_program";
import Button from "react-bootstrap/Button";
import Image from "../../images/img8.png";
import Banner from "../../images/MuscleLibraryCover.png";
import { Link, useLocation } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { cross_origin } from "../../../utils/constants";

function MusclesAnatomy({ getMusclesAnatomy, muscles_anatomy, user }) {
  const [musclesAnatomyData, setMusclesAnatomyData] = useState([]);

  const [tempMusclesAnatomyData, setTempMusclesAnatomyData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [keyword, setKeyword] = useState(null);
  const [loader, setLoader] = useState(true);
  const [productCat, setProductCat] = useState([]);

  let location = useLocation();

  // const [isShow, setVal] = useState(false)

  // Function to handle suggestion selection
  // const handleSuggestionClick = (suggestion) => {
  //     setVal(false);
  //     setKeyword(suggestion);
  // };

  let array = [
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
    {
      image: Image,
    },
  ];

  // useEffect(() => {
  //     getMusclesAnatomy(currentPage, itemsPerPage, 1, keyword)
  // }, [currentPage, itemsPerPage, keyword]);

  // useEffect(() => {
  //     setTotalPages(muscles_anatomy ? parseInt(muscles_anatomy.meta.last_page) : 1)
  //     if (muscles_anatomy?.data) {
  //         setLoader(false)
  //     }
  //     setMusclesAnatomyData(muscles_anatomy ? muscles_anatomy.data : [])
  //     // setMusclesAnatomyData(array)
  // }, [muscles_anatomy]);

  useEffect(() => {
    setMusclesAnatomyData(location?.state?.muscles_anatomy);
    setTempMusclesAnatomyData(location?.state?.muscles_anatomy);
    if (musclesAnatomyData) {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const topElement = document.querySelector(".muscle-banner-recommended"); // Replace 'top-element' with the actual ID of your top element
      if (topElement) {
        topElement.scrollIntoView({
          behavior: "smooth",
          block: "start", // Scroll to the top of the element
        });
      }
    }, 2000);
  }, []);
  const handleItemsPerPageChange = (e) => {
    setLoader(true);
    setItemsPerPage(parseInt(e.target.value));
  };

  const handleSearchClick = (value) => {
    // getMusclesAnatomy(1, itemsPerPage, 1, value);
    // setLoader(true);
    setCurrentPage(1);

    if (value.length < 1) {
      setMusclesAnatomyData(tempMusclesAnatomyData);
    } else {
      const searchResults = musclesAnatomyData.filter((data) => {
        return data.title.toLowerCase().includes(value.toLowerCase());
      });
      setMusclesAnatomyData(searchResults);
    }
  };
  const handleSearch = (value) => {
    // setVal(true);
    setKeyword(value);
    if (keyword && value.length == 0) {
      getMusclesAnatomy(currentPage, itemsPerPage, 1, value);
    }
  };
  return (
    <>
      <ScrollTopOnMount />
      <Header />
      {/* <div className={"muscle-banner-recommended"}> */}
      {/* <Container className={"h-100"}> */}
      {/* <Row className={"h-100"}> */}
      {/* <div className="bannerImgWrap">
                            <img
                                src={Banner}
                                alt={'banner'}
                                crossOrigin={cross_origin ? "anonymous" : null}
                            />
                        </div> */}

      {/*<Col className={"col-12 col-lg-6 col-md-6 m-auto"}>*/}
      {/*    <div className="box_banner_inner text-center">*/}
      {/*        <h3>Muscle Library</h3>*/}
      {/*    </div>*/}
      {/*</Col>*/}
      {/* </Row> */}
      {/* </Container> */}
      {/* </div> */}
      <div className={"clearfix"}></div>
      <section className="videos">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="videos_headings">
                <h2 className="detail_span" style={{ color: "#fff" }}>
                  MUSCLE LIBRARY
                </h2>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <Form
                className="d-flex mt-3"
                onSubmit={(e) => e.preventDefault()}
              >
                {/* <div className="autocomplete-container"> */}
                <Form.Control
                  value={keyword}
                  //onChange={handleInputChange}
                  //value={search}
                  type="search"
                  placeholder="Search"
                  className="me-2 rounded-pill"
                  aria-label="Search"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    handleSearchClick(e.target.value);
                  }}
                />

                {/* </div> */}
                {/* <Button className="rounded-pill" variant="outline-primary" type={'submit'} onClick={handleSearchClick}>
                                    TextField  Search
                                </Button> */}
              </Form>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div
                className={
                  "Educational-info d-flex flex-column text-start mb-3"
                }
              >
                <h3 className={"text-center"}>
                  {/*<p><b>Description section: </b>You can search the body part</p>*/}
                  <p>
                    Search by muscle name or region (i.e. hamstring, leg,
                    semitendinosus, etc.)
                  </p>
                </h3>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="row">
                {loader ? (
                  <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 ">
                    <div className={"empty-page"}>
                      <div className="spin-loader">
                        <FadeLoader color={"#EC1246"} height={10} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {" "}
                    {musclesAnatomyData?.length > 0 ? (
                      musclesAnatomyData.map((data, key) => {
                        if (data) {
                          return (
                            <div
                              className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 common-box-height "
                              key={key}
                            >
                              <Link
                                className={"nav-link library-nav"}
                                data-toggle="tab"
                                to={"/muscles-anatomy/detail/" + data?.id}
                                role="tab"
                              >
                                <div className="library-img-container">
                                  <div className="library-img-container-inner">
                                    {/* <h6 className='title-header' dangerouslySetInnerHTML={{ __html: data?.title }}></h6> */}
                                    <div>
                                      <div className="title-one">
                                        {data?.title}
                                      </div>
                                      <div className="title-two">
                                        {data?.title2}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="library-img-box">
                                    <img
                                      src={data?.image ? data?.image : ""}
                                      alt={data?.title ? data?.title : ""}
                                      crossOrigin={
                                        cross_origin ? "anonymous" : null
                                      }
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 ">
                        <div className={"library-empty-page"}>
                          <span className="itemsPerPagelabel">Not Found</span>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
              <div className="pagination">
                <span className="itemsPerPagelabel">Items Per Page:</span>
                <div className={"perpagedropdown"}>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={4}>4</option>
                    <option value={8}>8</option>
                    <option value={12}>12</option>
                    <option value={16}>16</option>
                    <option value={20}>20</option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M7 10l5 5 5-5z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </div>
                <span className="totalpages">
                  {currentPage}-{totalPages} of {totalPages}
                </span>
                <div className="paginationbuttons">
                  {currentPage > 1 ? (
                    <button
                      className={"button-previous"}
                      type="button"
                      onClick={() => {
                        setCurrentPage(currentPage - 1);
                        setLoader(true);
                      }}
                      disabled={currentPage === 1}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                    </button>
                  ) : (
                    ""
                  )}
                  {currentPage < totalPages ? (
                    <button
                      type="button"
                      className={"button-next"}
                      onClick={() => {
                        setCurrentPage(currentPage + 1);
                        setLoader(true);
                      }}
                      disabled={currentPage === totalPages}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    muscles_anatomy: state.programReducer.muscles_anatomy,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, { getMusclesAnatomy })(MusclesAnatomy);
