import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {cross_origin, EXERCISE_TIMES} from '../../../utils/constants'
import { Button } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'
import { updateUserProgramDay, updateProgramDayStatus } from '../../../actions/user_program'
import small_btn from '../../../css/beta.css'
import { useReactToPrint } from 'react-to-print';
import Broken from "../../images/NoImages.png";

const Assessment2Day1 = ({ exercises, updateUserProgramDay, programDayUpdateSuccess, dayId, programId, exercisePercentage, timePercentage, repsPercentage, updateProgramDayStatus }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const printRef = useRef(null)
    const [finishBtn, setFinishBtn] = useState(false)

    useEffect(() => {
        if ((repsPercentage && repsPercentage != null && repsPercentage > 0) || (timePercentage && timePercentage != null && timePercentage > 0)) {
            console.log('fffffffffff', repsPercentage, timePercentage, repsPercentage >= 0 || timePercentage > 0)
            setFinishBtn(true)
        }
    }, [exercisePercentage, timePercentage, repsPercentage])

    const handleFinishDayExercise = () => {
        updateUserProgramDay(dayId)
    }

    const changeDayId = (id, program_id) => {
        let dt = {
            is_active: 1,
            program_id: program_id,
        }
        updateProgramDayStatus(dt, id)
    }

    if (programDayUpdateSuccess) {
        dispatch({ type: 'USER_PROGRAM_UPDATE_PURGE' });
        if (localStorage.getItem('nextActiveDay') !== null && localStorage.getItem('nextActiveDay') !== undefined) {
            changeDayId(localStorage.getItem('nextActiveDay'), programId)
        }
        localStorage.setItem('lastActiveDayId', dayId)
        navigate(`/program/${programId}`, { replace: true });
    }

    const handlePrintSummary = useReactToPrint({
        content: () => printRef.current,
    });

    const countAverageReps = (data) => {
        let averageReps = 0
        let repsCount = data.length
        data.map((item, key) => {
            averageReps += averageReps + item.reps
        })
        return Math.round(averageReps / repsCount)
    }

    function getSeconds(timeString) {
        if (timeString && timeString != 0) {
            const [minutes, seconds] = timeString.split(' ');
            const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds);
            return totalSeconds;
        }
        return 0;
    }

    function getMinTime(totalSeconds) {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}min ${seconds}sec`;
    }

    const countAverageTime = (data) => {
        let averageTime = 0
        let timeCount = data.length
        data.map((item, key) => {
            averageTime += getSeconds(item.user_time)
        })
        if (averageTime != 0) {
            return getMinTime(averageTime / timeCount)
        }
        return 0
    }

    function getTimeValue(str) {
        let result = 0;
        var regex = /(\d+[a-z]+)/g;
        let match = regex.exec(str);
        while (match != null) {
            var match_str = match[0];
            var last_char = match_str[match_str.length - 1];
            if (last_char == 'h')
                result += parseInt(match_str) * 3600;
            if (last_char == 'min')
                result += parseInt(match_str) * 60;
            if (last_char == 'sec')
                result += parseInt(match_str);
            match = regex.exec(str);
        }
        return result;
    }

    const convertToTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
        return `${minutes} min ${remainingSeconds} sec`;
    };

    const goToHome = () => {
        navigate(`/program/${programId}`, { replace: true });
    }


    return (
        <section className="assestmentd1_002">
            <div className="container">
                {/*<div className="row mt-3">*/}
                {/*    <div className="col-12 col-lg-6 col-md-6 mt-3">*/}
                {/*        <Button type='button' onClick={() => handlePrintSummary()} className="theme_btn1 small_btn">Print Summary</Button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div id="print-content" ref={printRef}>
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12 text-center">
                            <ul className="suggested">
                                <li className="active">Suggested</li>
                                <li>Actual</li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-4 col-md-4">
                            <div className="box_p shadow_box">
                                <h6>Time</h6>
                                <div className="progress_bar">
                                    <div role="progressbar" aria-valuenow={timePercentage > 0 && timePercentage < 100 ? Math.round(timePercentage) : timePercentage > 100 ? 100 : 0} aria-valuemin="0" aria-valuemax="100"
                                        style={{ "--value": timePercentage > 0 && timePercentage < 100 ? Math.round(timePercentage) : timePercentage >= 100 ? 100 : 0 }}></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 col-md-4">
                            <div className="box_p shadow_box">
                                <h6>Exercises</h6>
                                <div className="progress_bar">
                                    <div role="progressbar" aria-valuenow={exercisePercentage > 0 && exercisePercentage < 100 ? Math.round(exercisePercentage) : exercisePercentage > 100 ? 100 : 0} aria-valuemin="0" aria-valuemax="100"
                                        style={{ "--value": exercisePercentage > 0 && exercisePercentage < 100 ? Math.round(exercisePercentage) : exercisePercentage >= 100 ? 100 : 0 }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-4">
                            <div className="box_p shadow_box">
                                <h6>Reps</h6>
                                <div className="progress_bar">
                                    <div role="progressbar" aria-valuenow={repsPercentage > 0 && repsPercentage < 100 ? Math.round(repsPercentage) : repsPercentage > 100 ? 100 : 0} aria-valuemin="0" aria-valuemax="100"
                                        style={{ "--value": repsPercentage > 0 && repsPercentage < 100 ? Math.round(repsPercentage) : repsPercentage >= 100 ? 100 : 0 }}></div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-lg-12 col-md-12">
                            <ul
                                className="nav nav-tabs row left_tabs slider_mobile "
                                role="tablist"
                            >
                                {exercises.map((item, key) =>
                                    <li key={key} className="nav-item  col-12 col-sm-12 col-lg-3 col-md-6">
                                        <div className="summary_box">
                                            <div className="summary_box_levelimg1">
                                                <img
                                                    src={item?.exerciseLevel?.video_cover}
                                                    alt="img41"
                                                    crossOrigin={cross_origin ? "anonymous" : null}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = Broken;
                                                    }}
                                                />
                                            </div>
                                            <div className="summary_title_box">
                                                <h6>{item?.exercise ? item?.exercise?.name : ''}</h6>
                                                <ul class="summary_time_list">
                                                    {item?.exerciseLevel?.reps && item?.exerciseLevel?.metric == 10 ?
                                                        <>
                                                            {item?.exerciseLevel?.suggested_weight_lbs ?
                                                                <>
                                                                    <li><span class="red">Suggested Weight: </span> <span class="time">{item?.exerciseLevel?.suggested_weight_lbs} lbs</span></li>
                                                                    <li><span class="red">Actual Weight: </span> <span class="time">{item?.user_lbs ? item?.user_lbs : '-'}</span></li>
                                                                </> : ''}
                                                            <li><span class="red">Suggested Reps: </span> <span class="time">{item?.exerciseLevel?.reps ? item?.exerciseLevel?.reps : '-'}</span></li>
                                                            {/*<li><span class="red">Actual Reps:</span><span class="time">{item.userProgramDayExercisesAttempt[0]?.reps  ? item.userProgramDayExercisesAttempt[0]?.reps  : ''}</span></li>*/}
                                                            {item?.userProgramDayExercisesAttempt !== undefined && item?.userProgramDayExercisesAttempt !== null && item?.userProgramDayExercisesAttempt.map((item, key) =>
                                                                <>
                                                                <li><span class="red">Circuit {key + 1}: </span> <span class="time">{item?.reps}</span></li>
                                                                <li><span className="red">Note {key + 1}: </span> <span
                                                                className="time">{item?.note ? item?.note : '-'}</span>
                                                                </li>
                                                                    </>
                                                            )}

                                                        </>
                                                        :
                                                        <>
                                                            {item?.exerciseLevel?.suggested_weight_lbs ?
                                                                <>
                                                                    <li><span class="red">Suggested Weight: </span> <span class="time">{item?.exerciseLevel?.suggested_weight_lbs} lbs</span></li>
                                                                    <li><span class="red">Actual Weight: </span> <span class="time">{item?.user_lbs ? item?.user_lbs : '-'}</span></li>
                                                                </> : ''}
                                                            <li><span class="red">Suggested Time: </span> <span class="time">{item?.exerciseLevel?.time ? EXERCISE_TIMES[item?.exerciseLevel?.time] : '-'}</span></li>
                                                            {/*<li><span class="red">Actual Time:</span><span className="time">{item.userProgramDayExercisesAttempt[0]?.duration ? convertToTime(item.userProgramDayExercisesAttempt[0]?.duration) : ''}</span></li>*/}
                                                            {item?.userProgramDayExercisesAttempt !== undefined && item?.userProgramDayExercisesAttempt !== null && item?.userProgramDayExercisesAttempt.map((item, key) =>
                                                                <>
                                                                <li><span class="red">Circuit {key + 1}: </span> <span class="time">{convertToTime(item?.duration)}</span></li>
                                                                <li><span className="red">Note {key + 1}: </span> <span
                                                                className="time">{item?.note ? item?.note : '-'}</span>
                                                                </li>
                                                                    </>
                                                            )}

                                                        </>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 col-lg-6 col-md-6 mt-3">
                        {finishBtn ?
                            <Button onClick={() => handleFinishDayExercise()} className="theme_btn1">Finish</Button> :
                            <Button onClick={() => goToHome()} className="theme_btn1">Go to Exercises</Button>
                        }
                    </div>
                </div>
            </div>
        </section >
    )
}

const mapStateToProps = state => ({
    programDayUpdateRequest: state.programReducer.programDayUpdateRequest || null,
    programDayUpdateSuccess: state.programReducer.programDayUpdateSuccess || null,
    programDayUpdateFailure: state.programReducer.programDayUpdateFailure || null,
});
export default connect(mapStateToProps, { updateUserProgramDay, updateProgramDayStatus })(Assessment2Day1);