import {
  EXERCISE_DETAIL_FAILURE,
  EXERCISE_DETAIL_REQUEST,
  EXERCISE_DETAIL_SUCCESS,
  GET_USER_PROGRAM,
  USER_PROGRAM_REQUEST,
  USER_PROGRAM_REQUEST_FAILURE,
  WEEK_SCHEDULE_FAILURE,
  WEEK_SCHEDULE_REQUEST,
  WEEK_SCHEDULE_SUCCESS,
  GET_TESTS_REQUEST,
  GET_TESTS,
  GET_TESTS_FAILURE,
  GET_TEST_FAILURE,
  GET_TEST,
  GET_TEST_REQUEST,
  GET_USER_PROGRAM_DAY_FAILURE,
  GET_USER_PROGRAM_DAY,
  GET_USER_PROGRAM_DAY_REQUEST,
  GET_QUESTION_FAILURE,
  GET_QUESTION,
  GET_QUESTION_REQUEST,
  CHECK_PREVIOUS_DAY_FAILURE,
  CHECK_PREVIOUS_DAY,
  CHECK_PREVIOUS_DAY_REQUEST,
  SUBMIT_CHECKIN_FEEDBACK_REQUEST,
  SUBMIT_CHECKIN_FEEDBACK,
  SUBMIT_CHECKIN_FEEDBACK_FAILURE,
  RECOMMENDED_PRODUCTS_SUCCESS,
  RECOMMENDED_PRODUCTS_REQUEST,
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  MUSCLES_CATEGORIES_REQUEST,
  MUSCLES_CATEGORIES_SUCCESS,
  TESTIMONIALS_REQUEST,
  TESTIMONIALS_SUCCESS,
  EDUCATIONAL_VIDEOS_SUCCESS,
  EDUCATIONAL_VIDEOS_REQUEST,
  EXERCISE_LEVELS_REQUEST,
  EXERCISE_LEVELS_SUCCESS,
  EXERCISE_LEVELS_FAILURE,
  LEVEL_CHANGE_REQUEST,
  LEVEL_CHANGE_SUCCESS,
  LEVEL_CHANGE_FAILURE,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAILURE,
  USER_PROGRAM_EXERCISE_UPDATE_REQUEST,
  USER_PROGRAM_EXERCISE_UPDATE_SUCCESS,
  USER_PROGRAM_EXERCISE_UPDATE_FAILURE,
  SUBMIT_CHECKOUT_FEEDBACK_REQUEST,
  SUBMIT_CHECKOUT_FEEDBACK,
  SUBMIT_CHECKOUT_FEEDBACK_FAILURE,
  USER_PROGRAM_UPDATE_REQUEST,
  USER_PROGRAM_UPDATE_SUCCESS,
  USER_PROGRAM_UPDATE_FAILURE,
  USER_SKIP_EXERCISE_COUNT_SUCCESS,
  USER_SKIP_EXERCISE_COUNT_FAILURE,
  USER_SKIP_EXERCISE_COUNT_REQUEST,
  EXERCISE_SUMMARY_REQUEST,
  EXERCISE_SUMMARY_SUCCESS,
  EXERCISE_SUMMARY_FAILURE,
  UPDATE_PROGRAM_STATUS_REQUEST,
  UPDATE_PROGRAM_STATUS_SUCCESS,
  UPDATE_PROGRAM_STATUS_FAILURE,
  FAQS_REQUEST,
  FAQS_SUCCESS,
  APT_VIDEOS_REQUEST,
  APT_VIDEOS_SUCCESS,
  APT_VIDEOS_FAILURE,
  MUSCLES_ANATOMY_SUCCESS,
  MUSCLES_ANATOMY_REQUEST,
  PAIN_POINT_REQUEST,
  PAIN_POINT_SUCCESS,
  MUSCLES_ANATOMY_DETAIL_FAILURE,
  MUSCLES_ANATOMY_DETAIL_REQUEST,
  MUSCLES_ANATOMY_DETAIL_SUCCESS,
  PAIN_POINT_DETAIL_REQUEST,
  PAIN_POINT_DETAIL_SUCCESS,
  PAIN_POINT_DETAIL_FAILURE,
  RECOMMENDED_PRODUCTS_CATEGORIES_SUCCESS,
  RECOMMENDED_PRODUCTS_CATEGORIES_FAILURE,
  RECOMMENDED_PRODUCTS_CATEGORIES_REQUEST,
  EXERCISE_TYPE_REQUEST,
  EXERCISE_TYPE_SUCCESS,
  EXERCISE_TYPE_FAILURE
} from "./types";
import api from "../utils/api";
import { setAlert } from "./alert";

export const getProgram =
  (id = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: USER_PROGRAM_REQUEST,
        });
        let token = localStorage.getItem("token");
        let url =
          id != null ? `/user-current-program/${id}` : "/user-current-program/";
        const res = await api.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        dispatch({
          type: GET_USER_PROGRAM,
          payload: res.data.data,
        });
      } catch (err) {
        dispatch({
          type: USER_PROGRAM_REQUEST_FAILURE,
        });
        const msg = err && err.response ? err.response.data.message : null;
        if (msg) {
          dispatch(setAlert("Can't fetch user program", "danger"));
        }
      }
    };

export const getWeekSchedule = (id) => async (dispatch) => {
  try {
    dispatch({
      type: WEEK_SCHEDULE_REQUEST,
    });
    const token = localStorage.getItem("token");
    // const program = JSON.parse(localStorage.getItem('program'))
    // const programId = program.id

    const res = await api.post(
      "/user-week-schedule",
      { program_id: id },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch({
      type: WEEK_SCHEDULE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: WEEK_SCHEDULE_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const getExerciseDetail = (day_id) => async (dispatch) => {
  try {
    dispatch({ type: EXERCISE_DETAIL_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get(`user-day-exercises/${day_id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    dispatch({
      type: EXERCISE_DETAIL_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: EXERCISE_DETAIL_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const getProgramTests = (program_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_TESTS_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get(`program-test/${program_id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_TESTS,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: GET_TESTS_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const getProgramTest = (test_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEST_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get(`tests/${test_id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_TEST,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: GET_TEST_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const getUserProgramDay = (day_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_PROGRAM_DAY_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get(`user-program-days/${day_id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_USER_PROGRAM_DAY,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: GET_USER_PROGRAM_DAY_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const getQuestion = (question_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_QUESTION_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get(`questions/${question_id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: GET_QUESTION,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: GET_QUESTION_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const checkPreviousDay = (current_day_id) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_PREVIOUS_DAY_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.post(
      "check-user-previous-day-exercises",
      {
        user_day_id: current_day_id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch({
      type: CHECK_PREVIOUS_DAY,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: CHECK_PREVIOUS_DAY_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const submitPreviousDayFeedback = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SUBMIT_CHECKIN_FEEDBACK_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.post("user-program-day-feedback", payload, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: SUBMIT_CHECKIN_FEEDBACK,
      payload: res.data.data,
    });
    const msg = res.data.message;
    dispatch(setAlert(msg, "success"));
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: SUBMIT_CHECKIN_FEEDBACK_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const getRecommendedProducts =
  (keyword = "") =>
    async (dispatch) => {
      try {
        const params = {};
        dispatch({ type: RECOMMENDED_PRODUCTS_REQUEST });
        const token = localStorage.getItem("token");
        if (keyword) {
          params["keyword"] = keyword;
        }
        const res = await api.get("/equipment-list", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: params, // Pass the pagination parameters as part of the request
        });

        dispatch({
          type: RECOMMENDED_PRODUCTS_SUCCESS,
          payload: res.data.data,
        });
      } catch (error) {
        const msg = error.response.data.message;
      }
    };

export const getCategories =
  (keyword = "") =>
    async (dispatch) => {
      try {
        const params = {};
        dispatch({ type: CATEGORIES_REQUEST });
        const token = localStorage.getItem("token");
        if (keyword) {
          params["keyword"] = keyword;
        }
        const res = await api.get("/equipment-by-category", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: params, // Pass the pagination parameters as part of the request
        });

        dispatch({
          type: CATEGORIES_SUCCESS,
          payload: res.data.data,
        });
      } catch (error) {
        const msg = error.response.data.message;
      }
    };

export const getByCategoryId = (id) => async (dispatch) => {
  try {
    dispatch({ type: RECOMMENDED_PRODUCTS_CATEGORIES_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get(`/get-equipments-by-category/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    console.log("res--<>", res);
    dispatch({
      type: RECOMMENDED_PRODUCTS_CATEGORIES_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    dispatch({
      type: RECOMMENDED_PRODUCTS_CATEGORIES_FAILURE,
    });
    dispatch(setAlert(msg, "danger"));
  }
};

export const getMusclesCategories =
  (keyword = "") =>
    async (dispatch) => {
      try {
        const params = {};
        dispatch({ type: MUSCLES_CATEGORIES_REQUEST });
        const token = localStorage.getItem("token");
        if (keyword) {
          params["keyword"] = keyword;
        }
        const res = await api.get("/muscles-by-category", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: params, // Pass the pagination parameters as part of the request
        });

        dispatch({
          type: MUSCLES_CATEGORIES_SUCCESS,
          payload: res.data.data,
        });
      } catch (error) {
        const msg = error.response.data.message;
      }
    };

export const getPainCategories =
  (keyword = "") =>
    async (dispatch) => {
      try {
        const params = {};
        dispatch({ type: MUSCLES_CATEGORIES_REQUEST });
        const token = localStorage.getItem("token");
        if (keyword) {
          params["keyword"] = keyword;
        }
        const res = await api.get("/pain-point-by-category", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: params, // Pass the pagination parameters as part of the request
        });

        dispatch({
          type: MUSCLES_CATEGORIES_SUCCESS,
          payload: res.data.data,
        });
      } catch (error) {
        const msg = error.response.data.message;
      }
    };

export const getTestimonials = () => async (dispatch) => {
  try {
    dispatch({ type: TESTIMONIALS_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get("/testimonials", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: TESTIMONIALS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
  }
};

export const getFaqs =
  (keyword = "") =>
    async (dispatch) => {
      try {
        const params = {};

        dispatch({ type: FAQS_REQUEST });
        const token = localStorage.getItem("token");
        if (keyword) {
          params["keyword"] = keyword;
        }
        console.log("keyword", keyword);

        const res = await api.get("/faqs", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: params, // Pass the pagination parameters as part of the request
        });
        dispatch({
          type: FAQS_SUCCESS,
          payload: res.data.data,
        });
      } catch (error) {
        const msg = error.response.data.message;
      }
    };

export const getExerciseType = (keyword) =>
  async (dispatch) => {
    try {
      const params = { is_paginate: 0 };
      dispatch({ type: EXERCISE_TYPE_REQUEST });
      const token = localStorage.getItem("token");
      if (keyword) {
        params["keyword"] = keyword;
      }
      const res = await api.get("/exercise-types", {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: params, // Pass the pagination parameters as part of the request
      });

      dispatch({
        type: EXERCISE_TYPE_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({ type: EXERCISE_TYPE_FAILURE });
      const msg = error.response.data.message;
    }
  };


export const getEducationalVideos =
  (program_id = null, page, perPage, is_paginate, unique_videos = "1", category = null) =>
    async (dispatch) => {
      try {
        console.log("category", category);
        
        dispatch({ type: EDUCATIONAL_VIDEOS_REQUEST });
        const token = localStorage.getItem("token");
        const params = {};

        if (program_id) {
          params["program_id"] = program_id;
        }

        if (category) {
          params["category"] = category;
        }

        if (page) {
          params["page"] = page;
        }

        if (perPage) {
          params["per-page"] = perPage;
        }

        params["is_paginate"] = is_paginate;

        params["unique_videos"] = unique_videos;

        const res = await api.get("/education-videos", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: params, // Pass the pagination parameters as part of the request
        });

        dispatch({
          type: EDUCATIONAL_VIDEOS_SUCCESS,
          payload: res.data.data,
        });
      } catch (error) {
        const msg = error.response.data.message;
      }
    };

export const getMusclesAnatomy =
  (
    page,
    perPage,
    is_paginate,
    keyword = "",
    order_column = "title",
    order = "asc"
  ) =>
    async (dispatch) => {
      try {
        dispatch({ type: MUSCLES_ANATOMY_REQUEST });
        const token = localStorage.getItem("token");
        const params = {};

        if (page) {
          params["page"] = page;
        }

        if (perPage) {
          params["per-page"] = perPage;
        }

        if (keyword) {
          params["keyword"] = keyword;
        }
        if (order_column) {
          params["order-column"] = order_column;
        }
        if (order) {
          params["order"] = order;
        }

        params["is_paginate"] = is_paginate;

        const res = await api.get("/muscles-anatomy-library", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: params, // Pass the pagination parameters as part of the request
        });

        dispatch({
          type: MUSCLES_ANATOMY_SUCCESS,
          payload: res.data.data,
        });
      } catch (error) {
        const msg = error.response.data.message;
      }
    };

export const getMusclesAnatomyData = (id) => async (dispatch) => {
  try {
    dispatch({ type: MUSCLES_ANATOMY_DETAIL_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get(`/get-muscles-details/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    dispatch({
      type: MUSCLES_ANATOMY_DETAIL_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: MUSCLES_ANATOMY_DETAIL_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const getPainPointData = (id) => async (dispatch) => {
  try {
    dispatch({ type: PAIN_POINT_DETAIL_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get(`/get-pain-point-details/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    dispatch({
      type: PAIN_POINT_DETAIL_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: PAIN_POINT_DETAIL_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const getPainPoint =
  (
    page,
    perPage,
    is_paginate,
    keyword = "",
    order_column = "title",
    order = "asc"
  ) =>
    async (dispatch) => {
      try {
        dispatch({ type: PAIN_POINT_REQUEST });
        const token = localStorage.getItem("token");
        const params = {};

        if (page) {
          params["page"] = page;
        }

        if (perPage) {
          params["per-page"] = perPage;
        }

        if (keyword) {
          params["keyword"] = keyword;
        }

        if (order_column) {
          params["order-column"] = order_column;
        }
        if (order) {
          params["order"] = order;
        }
        params["is_paginate"] = is_paginate;

        const res = await api.get("/pain-point-library", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: params, // Pass the pagination parameters as part of the request
        });

        dispatch({
          type: PAIN_POINT_SUCCESS,
          payload: res.data.data,
        });
      } catch (error) {
        const msg = error.response.data.message;
      }
    };

export const getAptVideos =
  (page, perPage, is_paginate) => async (dispatch) => {
    try {
      dispatch({ type: APT_VIDEOS_REQUEST });
      const params = {};
      if (page) {
        params["page"] = page;
      }
      if (perPage) {
        params["per-page"] = perPage;
      }
      params["is_paginate"] = is_paginate;
      const res = await api.get("/apt-education-videos", {
        params: params,
      });
      dispatch({
        type: APT_VIDEOS_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: APT_VIDEOS_FAILURE,
        payload: null,
      });
      // const msg = error.response.data.message;
    }
  };

export const getExerciseLevelsData = (id, level) => async (dispatch) => {
  try {
    dispatch({ type: EXERCISE_LEVELS_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get(`user-exercise-level/${id}/${level}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    dispatch({
      type: EXERCISE_LEVELS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: EXERCISE_LEVELS_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const updateExerciseLevel =
  (activeId, exerciseId, level) => async (dispatch) => {
    try {
      dispatch({ type: LEVEL_CHANGE_REQUEST });
      const token = localStorage.getItem("token");
      const res = await api.put(
        `user-program-day-exercises/${activeId}`,
        {
          exercise_id: exerciseId,
          exercise_level_id: level,
          // user_level: selectedLevel
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const msg = res.data.message;
      dispatch(setAlert(msg, "success"));
      dispatch({
        type: LEVEL_CHANGE_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      const msg = err.response.data.message;
      dispatch({ type: LEVEL_CHANGE_FAILURE });
      dispatch(setAlert(msg, "danger"));
    }
  };

export const GetCountries = () => async (dispatch) => {
  let params = {};
  try {
    params["is_paginate"] = 0;
    dispatch({ type: COUNTRIES_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.get(`countries`, {
      params,
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    dispatch({
      type: COUNTRIES_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: COUNTRIES_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const updateUserProgramDayExercise =
  (payload, id) => async (dispatch) => {
    let token = localStorage.getItem("token");
    try {
      dispatch({ type: USER_PROGRAM_EXERCISE_UPDATE_REQUEST });
      const res = await api.post(
        `/user-program-day-exercise-attempt`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch({
        type: USER_PROGRAM_EXERCISE_UPDATE_SUCCESS,
        payload: res.data,
      });
      let msg = res.data.message;
      dispatch(setAlert(msg, "success"));
      return res.data;
    } catch (err) {
      const msg = err.response.data.message;
      dispatch({ type: USER_PROGRAM_EXERCISE_UPDATE_FAILURE });
      dispatch(setAlert(msg, "danger"));
    }
  };

export const submitDayFeedback = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SUBMIT_CHECKOUT_FEEDBACK_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.post("user-program-day-exercise-feedback", payload, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({
      type: SUBMIT_CHECKOUT_FEEDBACK,
      payload: res.data.data,
    });
    const msg = res.data.message;
    dispatch(setAlert(msg, "success"));
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: SUBMIT_CHECKOUT_FEEDBACK_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const updateUserProgramDay = (activeId) => async (dispatch) => {
  try {
    dispatch({ type: USER_PROGRAM_UPDATE_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.put(
      `user-program-days/${activeId}`,
      {
        status: 20,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const msg = res.data.message;
    dispatch(setAlert(msg, "success"));
    dispatch({
      type: USER_PROGRAM_UPDATE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: USER_PROGRAM_UPDATE_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const countSkipExercise = (id, weeklySummary) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SKIP_EXERCISE_COUNT_REQUEST,
    });
    let token = localStorage.getItem("token");
    const res = await api.get(
      `user-skip-exercises-count/${id}/${weeklySummary}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    dispatch({
      type: USER_SKIP_EXERCISE_COUNT_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err && err.response ? err.response.data.message : null;
    if (msg) {
      dispatch(setAlert(msg, "danger"));
    }
    dispatch({
      type: USER_SKIP_EXERCISE_COUNT_FAILURE,
    });
  }
};

export const getExerciseSummaryDetail =
  (day_id, weeklySummary) => async (dispatch) => {
    try {
      dispatch({ type: EXERCISE_SUMMARY_REQUEST });
      const token = localStorage.getItem("token");
      const res = await api.get(
        `user-day-exercises-summary/${day_id}/${weeklySummary}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      dispatch({
        type: EXERCISE_SUMMARY_SUCCESS,
        payload: res.data.data,
      });
    } catch (err) {
      const msg = err.response.data.message;
      dispatch({ type: EXERCISE_SUMMARY_FAILURE });
      dispatch(setAlert(msg, "danger"));
    }
  };

export const updateProgramDayStatus = (dt, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROGRAM_STATUS_REQUEST });
    const token = localStorage.getItem("token");
    const res = await api.put(`user-program-day-status/${id}`, dt, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    // const msg = res.data.message;
    // dispatch(setAlert(msg, 'success'))
    dispatch({
      type: UPDATE_PROGRAM_STATUS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    dispatch({ type: UPDATE_PROGRAM_STATUS_FAILURE });
    dispatch(setAlert(msg, "danger"));
  }
};

export const GetPages = async () => {
  const token = localStorage.getItem("token");
  const res = await api.get("pages?page=1&per-page=100&is_paginate=1", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res.data;
};
